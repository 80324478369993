
.ApplyForIndustry {
  width: 100%;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #101010;
  margin-top: 20px;

 
}
.el-button:focus,
.el-button:hover {
  background: #14569f;
  border-radius: 4px;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
}
.el-form {
  width: 80%;
  margin: 0 auto;
}
h2 {
  text-align: center;
  font-weight: normal;
  font-size: 28px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #101010;
  margin-bottom: 20px;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.subContainer {
  // display: ;
  text-align: center;
}
.submitBtn {
  width: 180px;
  height: 42px;
  background: #14569f;
  border-radius: 4px;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
}
